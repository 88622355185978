.logo-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease;
  z-index: 1000;
}

.logo-animation-container.animate {
  opacity: 1;
  visibility: visible;
}

.animation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.logo-wrapper {
  width: 400px; /* Logo mucho más grande */
  height: 160px;
  position: relative;
}

.animated-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInLogo 1s ease forwards;
}

.success-message {
  text-align: center;
  opacity: 0;
  animation: fadeInMessage 0.5s ease forwards 0.8s;
}

.message-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.message-row h1 {
  font-size: 2rem;
  color: #333;
  margin: 0;
  font-weight: 500;
}

/* Checkmark más pequeño y al lado del texto */
.checkmark-wrapper {
  width: 40px;
  height: 40px;
  position: relative;
}

.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #4CAF50;
  stroke-miterlimit: 10;
  box-shadow: 0 0 0 rgba(76, 175, 80, 0.4);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke: #4CAF50;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: #4CAF50;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes fadeInLogo {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInMessage {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 100px #fff;
  }
}

/* Animación de salida */
.logo-animation-container:not(.animate) {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s linear 0.5s;
}