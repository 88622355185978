.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.scroll-bottom {
  scrollbar-gutter: stable;
  scroll-behavior: auto;
}

.dropdown-menu {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.dropdown-menu.show {
  max-height: 300px; /* Ajusta este valor según el contenido */
  opacity: 1;
}


/* InviteUser.css */

.invite-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.invite-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-field label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.input-field input[type="text"],
.input-field input[type="email"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.input-field input[type="checkbox"] {
  margin-right: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
  position: relative;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content form {
  margin-top: 20px;
}

.popup-content label {
  display: block;
  margin-bottom: 10px;
}

.popup-content input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.popup-content button {
  align-items: center; /* Alinea los elementos verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:disabled {
  background-color: #1A7D71;
  cursor: not-allowed;
}


.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #54C6C6;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  align-items: center; /* Alinea los elementos verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #2F8E8E;
}

.message {
  margin-top: 10px;
  text-align: center;
}

.message p {
  margin: 0;
  font-size: 16px;
}

.message .success {
  color: #27ae60;
}

.message .error {
  color: #e74c3c;
}

.submit-button:disabled {
  background-color: #1A7D71;
  cursor: not-allowed;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fondo-imagen {
  background-image: url('./img/dashboard2.jpg');
  background-size: cover;
  background-position: center;
}

.placeholder-white::placeholder {
  color: white;
  opacity: 1; /* Asegura que el placeholder sea completamente opaco */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
