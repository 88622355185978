/* Estilos generales */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}


.animate-slide-in {
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

  .gantt-container {
  }



  .task-row {
    transition: all 0.3s ease;
  }
  
  .task-row.not-applicable {
    position: relative;
    color: #9CA3AF;
  }
  
  .task-row.not-applicable td {
    position: relative;
  }
  
  .task-row.not-applicable td::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: #9CA3AF;
    pointer-events: none;
  }
  
  .task-row.not-applicable .task-label {
    position: relative;
    margin-right: 70px; /* Espacio para la etiqueta NO APLICA */
  }
  
  .task-row.not-applicable .task-label::after {
    content: 'NO APLICA';
    position: absolute;
    right: -65px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #EF4444;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.65rem;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .task-row.not-applicable:hover {
    background-color: rgba(156, 163, 175, 0.1);
  }


/* Contenedor principal */
.login-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: url('../img/background.png') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

/* Caja de login */
.login-box {
  background: rgba(105, 205, 208, 0.8); /* Fondo con color y transparencia */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 150px;
  top: 50%;
  transform: translateY(-50%);
}



/* Logo */
.login-logo {
  width: 200px; /* Establece el ancho máximo del logo al 100% del contenedor */
  height: 100px; /* Asegura que la altura se ajuste automáticamente manteniendo la proporción */
  background-image: url('../img/logo3.png'); /* Ruta a tu imagen de logo */
  background-size: contain; /* Ajusta el tamaño de la imagen para que se ajuste al contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen dentro del contenedor */

}
/* Iconos para campos de texto */
.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #556A6B; /* Color de icono */
}



.success-message {
  color: green;
  display: flex; /* Usa flexbox para alinear el texto y el spinner */
  align-items: center; /* Alinea verticalmente el spinner con el texto */
  font-size: 16px;
  margin-top: 10px;

}


/* Contenedor de inputs */
.input-container {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

/* Inputs de login */
.login-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

/* Login.css */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-left: 4px solid #4ADBC9; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 8px; /* Espacio entre el spinner y el texto */
  animation: spin 1s linear infinite;
}
/* Estilo para el spinner */
.spinner-container {
  display: flex;
  align-items: center; /* Alinea el spinner y el texto verticalmente */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.login-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}


.login-button {
  width: 100%;
  padding: 10px;
  background-color: #556A6B;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #3f4e4e;
}


.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.fa-spinner {
  margin-right: 8px;
}

.input-container input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}


/* Mensaje de error */
.error-message {
  color: red;
  margin-top: 10px;
}

/* Triángulo principal */
.triangle {
  width: 0;
  height: 0;
  border-left: 30vw solid transparent;

  border-top: 150vh solid rgba(255, 215, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2; /* Cambiado a 1 para que esté encima del triángulo amarillo */
}

/* Ocultar el triángulo en dispositivos móviles en orientación vertical */
@media screen and (max-width: 767px) and (orientation: portrait) {
  .triangle {
    display: none;
  }
}

.login-input {
  padding-left: 30px; /* Ajusta este valor para mover el texto a la derecha */
}

/* Línea amarilla */
.triangle::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 30vw solid transparent;
  border-top: 150vh solid rgba(103, 212, 216, 0.87);
  position: fixed;
  right: -20px;
  top: -20px;
  z-index: 1; /* Cambiado a 0 para que esté detrás del triángulo principal */
}


